html {
  scroll-behavior: smooth;
}

/* SCROLLBAR*/
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #797474;
          box-shadow: inset 0 0 5px #797474;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #11415B;
  border-radius: 10px;
}


body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  background-image: radial-gradient(circle, #7db1cd, #90bdd3, #a4cad9, #b8d6e0, #cde2e8);
  font-family: -apple-system,'Segoe UI', 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.navbar {
  z-index: 1000;
  font-size: 18px;
  padding: 2rem 2rem;
}
.navbar-light .navbar-brand {
  color: white!important;
  font-weight: bold;
}
.navbar-light .navbar-nav .nav-link {
  color: white!important;
}
.navbar-light .navbar-nav .nav-link:hover {
  transition: 0.5s;
  color: lightskyblue!important;
}
span.navbar-toggler-icon {
  border-top: 1px solid lightblue;
  border-bottom: 1px solid lightblue;
}


.Home {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

#alert1 {
  opacity: 0.5;
  padding: 15px;
}


.AreaOne {
  /* background: url(components/img/head.jpg)no-repeat; */
  background-image: linear-gradient(to bottom, #11415b, #41667c, #6d8e9e, #9cb7c2, #cde2e8);
  background-size: cover;
  width: 100%;
  height: 670px;
  text-align: center;
}
.AreaOne h1 {
  color: #cde2e8;
  padding-top: 200px;
  font-size: 100px;
}
.AreaOne h5 {
  color: white;
  font-size: 22px;
  padding-bottom: 30px;
}
.AreaOne .Onebutton {
  color: white;
  background-color: darkblue;
  text-decoration: none;
  font-size: 15px;
  padding: 10px;
}
.AreaOne .Onebutton:hover {
  transition: 0.5s;
  background-color: #11415b;
}
.AreaOne .contactInfo {
  position: absolute;
  text-align: left;
  margin: 0 10px;
  top: 550px;
}
.AreaOne .contactInfo p {
  color: white;
  font-size: 14.5px;
}
.AreaOne img {
  width: 20px;
  height: 20px;
  margin: 5px 10px;
}


.AreaTwo {
  background: #cde2e8;
  width: 100%;
  height: auto;
  text-align: center;
  padding: 50px 0px;
}
.AreaTwo h1 {
  color: #11415B;
  font-size: 40px;
  padding: 40px 0;
  text-decoration-line: underline;
  text-decoration-style: double;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.AreaTwo .twoIcons {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.AreaTwo .twoIcons .iconType { margin: 15px; }
.AreaTwo .twoIcons .iconType p { font-weight: bold; color: #11415B;}
.AreaTwo .twoIcons .iconType img {
  width: 45px;
  height: 45px;
  margin: 15px;
}
.AreaTwo .twoExcerpt {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.AreaTwo .twoExcerpt .excerptText {
  width: 30%;
  padding: 15px;
  margin: 80px 5px;
  border-left: 5px solid lightblue;
}
.AreaTwo .twoExcerpt .excerptText h4 {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}


.AreaThree {
  text-align: center;
  background-color: #11415B;
}
.threeHead {
  width: 100%;
  padding: 30px;
}
.threeHead h1 {
  color: #cde2e8;  
  text-decoration-line: underline;
  text-decoration-style: double;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.threePackages {
  display: flex;
  text-align: left;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding: 0px 40px;
  background-color: #cde2e8;
}
.offers {
  width: 28%;
  padding-top: 0px;
  white-space: nowrap;
  margin: 150px 20px 20px 20px;
  background-color: #cde2e8;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
}
.offers ul li {
  font-size: 20px;
  color: #11415B;
  white-space: nowrap;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.offers img {
  padding: 2px;
  width: 40px;
  height: 40px;
  margin: 10px;
  border: 1px solid #11415B;
  border-radius: 50%;
}

.threeMore {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: #cde2e8;
  padding: 80px 10px 80px 10px;
}
.moreTitle {
  display: none;
  width: 15%;
  text-align: center;
  padding: 0 20px;
  overflow: hidden;
}

.moreText {
  width: 85%;
  text-align: left;
}
.moreText ul li {
  font-size: 18.5px;
  line-height: 2;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  color: #11415B;
}


.AreaFour {
  text-align: center;
  background-color: #cde2e8;
}
.fourHead {
  width: 100%;
  padding: 30px;
  background-color: #11415B;
}
.fourHead h1 {
  color: #cde2e8;
  text-decoration-line: underline;
  text-decoration-style: double;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.fourProject {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 20px 100px 20px;
  margin-top: 70px;
}
.project {
  width: 55%;
  padding: 20px;
}
.project a h5 {
  margin-bottom: 20px;
  color: #11415B;
  font-weight: 100;
  cursor: pointer;
  text-decoration: underline;
}
.project h5:hover {
  opacity: 0.5;
  transition: 0.5s;
}
.project .carousel-inner {
  border-radius:12px;
  border: 1px solid black;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.about {
  width: 45%;
  margin: 100px 0px;
  padding: 50px 80px;
}
.about img {
  width: 98px;
  height: 95px;
  border-radius: 50%;
  margin: 20px;
  transition: 0.8s;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.about img:hover {
  transition: 0.8s;
  filter: contrast(150%);
}
.about h6 {
  font-size: 18px;
  font-weight: 200;
  color: #11415B;
}



/* Footer */
.footer {
  width: 100%;
  padding: 50px 20px 40px 20px; 
  color: #cde2e8;
  background-color: #11415B;
  /* background-image: linear-gradient(to top, #11415b, #41667c, #6d8e9e, #9cb7c2, #cde2e8); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
/*Social Media Icons*/
.smediaFooter img {
  width: 25px;
  height: 25px;
  margin: 0px 5px;
  border-radius: 10%;
  transition: 0.5s;
}
.smediaFooter img:hover {
  border-radius: 50%;
}
.smediaFooter p {
  margin-top: 10px;
  margin-bottom: 5px;
}
/*Website Name*/
.webName {
  padding: 15px 10px 0 15px;
}
.webName a {
  transition: 0.5s;
  color: #cde2e8;
  font-size: 13px;
}
.webName a:hover {
  color: lightblue;
}


/* PORTRAIT & LANDSCAPE PHONE */
/* =========== Phone format ============= */
@media (max-width: 915px) {
  #alert1 {
    display: none;
  }

  .AreaOne h1 {
    padding-top: 200px;
    font-size: 45px;
  }
  .AreaOne h5 {
    font-size: 18px;
    padding-bottom: 30px;
  }


  .AreaTwo {
    padding: 0px 0px 20px 0px;
  }
  .AreaTwo h1 {
    font-size: 30px;
    padding: 30px 0;
    text-decoration-line: underline;
    text-decoration-style: double;
  }
  .AreaTwo .twoIcons {
    display: none;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .AreaTwo .twoExcerpt {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .AreaTwo .twoExcerpt .excerptText {
    width: 100%;
    padding: 10px;
    margin: 10px 10px;
  }
  .AreaTwo .twoExcerpt .excerptText h4 {
    font-size: 18px;
  }
  

  .threeHead {
    width: 100%;
    padding: 20px;
  }
  .threeHead h1 {
    font-size: 30px;
    text-decoration-line: underline;
    text-decoration-style: double;
  }
  .threePackages {
    display: flex;
    text-align: left;
    flex-direction: column;
    padding: 20px 10px;
  }
  .offers {
    padding-top: 5px;
    width: 100%;
    margin: 5px;
  }
  .offers h3 {
    font-size: 25px;
  }
  .offers img {
    width: 30px;
    height: 30px;
    margin: 10px;
  }
  .offers ul li {
    font-size: 20px;
  }
  .offers .remove{
    display: none;
  }
  .threeMore {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 25px 15px 50px 5px;
  }
  .moreTitle {
    display: block;
    width: 100%;
  }
  .moreTitle h1 { 
    font-size: 25px;
  }
  .moreText {
    width: 100%;
    text-align: left;
  }
  .moreText ul li {
    font-size: 18px;
    line-height: 1.5;
  }
  

  .fourHead h1 {
    font-size: 30px; 
    text-decoration-line: underline;
    text-decoration-style: double;
  }
  .fourProject {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px 10px 70px 10px;
  }
  .project {
    width: 100%;
    padding: 10px 0px;
  }
  .about {
    width: 100%;
    padding: 20px;
    margin: 20px 0px;
  }
  .about img {
    width: 108px;
    height: 100px;
    border-radius: 50%;
    margin: 20px;
  }
  .about h6 {
    font-size: 18px;
  }
}


/* =========== Tablet Format ============= */
/* @media (min-width: 801px) and (max-width: 1224px) {} */
@media (min-width: 916px) and (max-width: 1023px) {
  .offers ul li {
    font-size: 16px;
  }
}


/* =========== Large Wide Screen format ============= */
@media (min-width: 1740px) {
  
  .AreaOne {
    height: 850px;
  }
  .AreaOne h1 {
    padding-top: 280px;
  }
  .AreaOne .contactInfo {
    top: 700px;
  }
  .AreaTwo .twoExcerpt .excerptText {
    width: 20%;
  }
  .offers {
    width: 20%;
  }
  .moreText {
    width: 60%;
  }
  .project {
    width: 40%;
  }
  .about {
    width: 30%;
  }
}